<template>
<div>
	<!-- Image Container -->
	<div v-if="dataImg == null" class="text-center pointer disabled" >
		<vs-avatar class="text-4xl" :color="getColor(client && client.name)" :text="getInitial(dataName)" size="70px">
		</vs-avatar>
	</div>
	<div  class="space-y-4">
		<div>
			<vs-input v-filter-input
				v-validate="'required'"
				data-vv-validate-on="blur"
				name="name"
				type="text"
				:label="$t('Fullname')"
				:placeholder="$t('Fullname')"
				class="w-full"
				v-model="dataName"
			/>
			<span class="text-danger text-sm">{{ $t(errors.first('name')) }}</span>
		</div>
		<div>
			<vs-input v-filter-input-email
				v-validate="'required|email'"
				data-vv-validate-on="blur"
				name="email"
				type="email"
				:label="$t('Email')"
				:placeholder="$t('Email')"
				class="w-full"
				v-model="dataEmail"
			/>
			<span class="text-danger text-sm">{{ $t(errors.first('email')) }}</span>
		</div>
		<div>
			<vs-input v-filter-input
				v-validate="'required'"
				data-vv-validate-on="blur"
				name="company"
				type="text"
				:label="$t('Company')"
				:placeholder="$t('Company')"
				class="w-full"
				v-model="dataCompany"
			/>
			<span class="text-danger text-sm">{{ $t(errors.first('company')) }}</span>
		</div>
		<div class="text-right">
			<vs-button
        :class="{'is-loading': isSaving}"
				:disabled="!isFormValid"
				@click="submitData()"
				color="primary"
				type="filled"
				class="submit-button"
				>
				{{ $t('Save') }}
			</vs-button>
		</div>
	</div>
</div>
</template>

<script>
/* eslint-disable camelcase */
import fileApi from '@/api/file';
import {
  duplicateVar,
  getFirstCharacter,
  generateColorByAlphabet,
  getAxiosErrorMessage,
} from '@/lib/helper';
import clientsApi from '@/api/user';

export default {
  components: {
  },
  props: {
    client: {
      type: Object,
      default: () => {},
    },
    eventId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      languageOptions: [
        { value: 'no', text: 'Norsk' },
        { value: 'en', text: 'English' },
      ],
      dataId: null,

      dataName: '',
      dataCompany: '',
      dataEmail: '',
      dataLanguage: 'en',

      dataImg: null,
      isSaving: false,
      isUploading: false,
    };
  },
  computed: {
    params() {
      const params = {
        id: this.dataId,
        email: this.dataEmail,
        name: this.dataName,
        company: this.dataCompany,
        picture: this.dataImg,
        language: this.dataLanguage,
      };
      return params;
    },
    isFormValid() {
      return (
        !this.errors.any()
				&& this.dataEmail !== ''
				&& this.dataName !== ''
				&& this.dataLanguage !== ''
				&& this.dataCompany !== ''
				&& !this.isUploading
      );
    },
  },
  methods: {
    setData() {
      const client = duplicateVar(this.client);

      this.dataId = client.id;
      this.dataEmail = client.email;
      this.dataCompany = client.company;
      this.dataName = client.name;
      this.dataLanguage = client.language;
      // this.dataImg = picture;
      this.initValues();
    },
    initValues() {
      if (this.client.id) return;
      this.dataId = null;
      this.dataEmail = '';
      this.dataCompany = '';
      this.dataName = '';
      this.dataImg = null;
      this.dataLanguage = 'en';
    },
    submitData() {
      const submit = (result) => {
        this.isSaving = true;
        if (!result) return;
        const title = this.$t('Client');
        const isUpdate = !!this.dataId;
        const params = this.params;
        const callback = (response) => {
          const item = response.data;
          this.$store.dispatch('setClient', item);
          const message = response.message;
          this.$emit('add', item);
          this.$emit('close');
          this.initValues();
          this.$vs.notify({
            title,
            text: message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success',
          });
          this.isSaving = false;
        };
        const errorCallback = (e) => {
          const message = getAxiosErrorMessage(e);
          this.$vs.notify({
            title,
            text: message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          });
          this.isSaving = false;
        };
        if (isUpdate) {
          clientsApi.update(this.dataId, params, callback, errorCallback);
        } else {
          clientsApi.create(params, callback, errorCallback);
        }
      };
      this.$validator.validateAll().then(submit);
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        this.isUploading = true;
        const file = input.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
          this.dataImg = e.target.result;
        };
        reader.readAsDataURL(file);

        // Upload Image
        const formData = new FormData();
        formData.append('file', file);

        const callback = (response) => {
          const url = response.data;
          this.dataImg = url;
          this.isUploading = false;
        };

        const errorCallback = (e) => {
          const message = getAxiosErrorMessage(e);
          this.$vs.notify({
            title: this.$t('Upload Image'),
            text: message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          });
          this.isUploading = false;
        };

        fileApi.upload(formData, callback, errorCallback);
      }
    },
    removeImage() {
      this.dataImg = null;
      this.$refs.updateImgInput.value = '';
    },
    getInitial(str) {
      if (str) {
        return getFirstCharacter(str);
      }
      return 'A';
    },
    getColor(str) {
      return generateColorByAlphabet(str);
    },
  },
  mounted() {
    this.initValues();
  },
  created() {
    this.setData();
  },
  destroyed() {
  },
};
</script>
