<template>
	<div>
		<div v-if="dataImg == null" class="text-center pointer" @click="$refs.updateImgInput.click()">
		<vs-avatar v-if="!client" size="70px">
			<img src="@/assets/images/file-icons/default-img.jpg" alt="asd" class="responsive pointer" />
		</vs-avatar>
		<vs-avatar class="text-4xl" :color="getColor(client && client.name)" :text="getInitial(dataName)" v-if="client" size="70px">
		</vs-avatar>
		</div>
		<div v-if="dataImg != null" class="text-center" @click="$refs.updateImgInput.click()">
			<img :src="dataImg" alt="img" style="width: 70px; height: 70px" class="responsive rounded-full" />
		</div>
		<div class="space-y-6">
			<div>
				<div class="text-gray border-bottom-2">{{ $t('Name') }}</div>
				<div>{{dataName}}</div>
			</div>
			<div>
				<div class="text-gray">{{ $t('Email') }}</div>
				<div>{{dataEmail}}</div>
			</div>
			<div>
				<div class="text-gray">{{ $t('Company') }}</div>
				<div>{{dataCompany}}</div>
			</div>
			<div>
				<div class="text-gray">{{ $t('Language') }}</div>
				<div>{{dataLanguage}}</div>
			</div>
		</div>
		<div class="mt-8 flex justify-between items-center">
			<div class="text-blue pointer" @click="showDeleteClient()">
				Delete this profile
			</div>
			<vs-button
				@click="editToggle()"
				color="primary"
				type="filled"
				class="submit-button"
				>
				{{ $t('Edit') }}
			</vs-button>
		</div>
	</div>
</template>

<script>
/* eslint-disable camelcase */
import {
  duplicateVar,
  getFirstCharacter,
  generateColorByAlphabet,
} from '@/lib/helper';

export default {
  components: {
  },
  props: {
    client: {
      type: Object,
      default: () => {},
    },
    eventId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      languageOptions: [
        { value: 'no', text: 'Norsk' },
        { value: 'en', text: 'English' },
      ],
      dataId: null,
      dataEventId: null,

      dataName: '',
      dataCompany: '',
      dataEmail: '',
      dataLanguage: 'en',

      dataImg: null,
      isSaving: false,
      isUploading: false,
    };
  },
  computed: {
  },
  methods: {
    showDeleteClient() {
      this.$emit('showDeleteClient', this.client);
    },
    setData() {
      const client = duplicateVar(this.client);

      this.dataId = client.id;
      this.dataEmail = client.email;
      this.dataCompany = client.company;
      this.dataName = client.name;
      this.dataLanguage = client.language;
    },

    editToggle() {
      this.$emit('editToggle');
    },
    getInitial(str) {
      return getFirstCharacter(str);
    },
    getColor(str) {
      return generateColorByAlphabet(str);
    },
  },
  mounted() {
  },
  created() {
    this.setData();
  },
  destroyed() {
  },
};
</script>
