<template>
	<modal
		class="modal-profie"
		size="small-medium"
		:hide-footer="true"
		:show-close="true"
		:isEnableKeyUp="false"
		@close="close"
		v-if="show">
		<div slot="header">
			<h5 class="modal-title" v-if="Object.entries(this.client).length === 0">{{  $t('Add') }} <span>{{ $t('Client') }}</span></h5>
			<h5 class="modal-title" v-if="Object.entries(this.client).length > 0 && !isEdit && client.user && client.user && client.user.name">{{ $t('Profile') }} <span>{{ client.user.name }}</span></h5>
			<h5 class="modal-title" v-if="Object.entries(this.client).length > 0 && isEdit">{{  $t('Edit') }} <span>{{ $t('Client') }}</span></h5>
		</div>
		<div slot="body">
			<div>
				<client-edit-form @close="close" @add="add" :client="client" :eventId="eventId" v-if="isEdit || Object.entries(this.client).length === 0" />
				<client-details @showDeleteClient="showDeleteClient" @editToggle="editToggle" :client="client" v-if="!isEdit && Object.entries(this.client).length > 0" />
			</div>
		</div>
	</modal>
</template>

<script>
import { mapGetters } from 'vuex';
import Modal from '@/components/Modal.vue';
import ClientEditForm from '@/components/clients/form/ClientEditForm.vue';
import ClientDetails from '@/components/clients/form/ClientDetails.vue';

export default {
  components: {
    Modal,
    ClientEditForm,
    ClientDetails,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    eventId: {
      type: Number,
      default: null,
    },
    client: {
      type: Object,
      default: null,
    },
    isEditFromParent: {
      type: Boolean,
      default: false,
    },
    isDetailsFromParent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      accessCode: null,
      isSubmitting: false,
      isEdit: false,
      isSaving: false,
      dataClient: {
        name: '',
        company: '',
        email: '',
        language: '',
      },
    };
  },
  watch: {
    show() {
      if (this.show) {
        if (this.isEditFromParent) {
          this.isEdit = true;
        }
        if (this.isDetailsFromParent) {
          this.isEdit = false;
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      user: 'user',
    }),
  },
  methods: {
    close() {
      this.$emit('close');
      this.isEdit = false;
    },
    add(item) {
      this.$emit('add', item);
    },
    editToggle() {
      this.isEdit = true;
    },
    showDeleteClient(item) {
      this.$emit('showDeleteClient', item);
    },
  },
  mounted() {
  },
  created() {
  },
  destroyed() {
  },
};
</script>
